<template>
  <v-container>
    <h1 class="text-center" v-if="form.id">Редактировать клиента <b class="grey--text">№{{ form.id }}</b></h1>
    <h1 class="text-center" v-else>Создать клиента</h1>
    <h1 class="text-center">{{ form.surname + ' ' + form.name + ' ' + form.second_name}}</h1>
      <form>
        <v-row class="mt-10" no-gutter>
          <v-col cols="12"
                 md="12" lg="4" class="my-0 py-0">
            <v-text-field
                v-model="form.surname"
                label="Фамилия"
                required
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6" lg="4" class="my-0 py-0">
            <v-text-field
                v-model="form.name"
                label="Имя"
                required
                outlined
            ></v-text-field>
          </v-col>
          <v-col cols="12" xs="6" md="6" lg="4" class="my-0 py-0">
            <v-text-field
                v-model="form.second_name"
                label="Отчество"
                required
                outlined
            ></v-text-field>
          </v-col>


            <v-col
                cols="12"
                md="6"
                offset-md="3"
                class="my-0 py-0"
            >
              Дата рождения

              <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  full-width
                  max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                      :value="computedDateFormattedMomentjs"
                      clearable
                      readonly
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="form.birthday"
                    @change="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col></v-row>


        <v-text-field class="mt-10"
            v-model="form.phone"
            label="Телефон"
            required
            outlined
        ></v-text-field>
        <v-text-field
            v-model="form.email"
            label="E-mail"
            required
            outlined
        ></v-text-field>
        <v-row>
          <v-col cols="12" sm="2" >Скидка</v-col>
          <v-col cols="12" sm="10">
            <v-btn-toggle v-model="form._discount" tile color="primary" group>
              <v-btn v-for="(sale, index) in discount" :key="index" :value="sale">
                {{ sale }}%
              </v-btn>
            </v-btn-toggle>
          </v-col>
        </v-row>

        <v-switch
            v-model="form.is_vip"
            inset
            label="VIP клиент?"
            class="mb-5"
        ></v-switch>

        <v-textarea
            outlined
            name="address"
            v-model="form.address_delivery"
            label="Адрес доставки"
        ></v-textarea>

        <v-textarea
            outlined
            name="note"
            v-model="form.note"
            label="Примечание"
        ></v-textarea>

        <v-row align-content="center">
          <v-col cols="12" class="text-center">
            <v-btn
                @click="submitReset"
                class="mr-10"
                color="red"
                dark
                rounded
                large
            >Отменить
            </v-btn>

            <v-btn
                @click="submitSave"
                color="green"
                dark
                rounded
                large
            >Сохранить
            </v-btn>
          </v-col>

        </v-row>


      </form>
    </v-container>
</template>

<script>
import moment from 'moment';
import HTTP from "../api";

export default {

  data() {
    return {
      client: {
        is_vip: false,
        address_delivery: '',
        name: '',
        second_name: '',
        surname: '',
        phone: '',
        email: '',
        note: "Пожелания:  \n" +
            "Любимый аромат:  ",
        birthday: new Date().toISOString().substr(0, 10),
        _discount: 0,
      },
      discount: [0,5,10,15],
      menu1: false,
      form: {}
    };
  },
  methods: {
    submitReset() {
      this.form = {...this.client }
    },
    submitSave() {
      if (this.$route.name !== 'client-create') {
        HTTP.put(`clients/${this.form.id}/`, {...this.form}).then(response => {
          this.$router.push({ name: 'client-detail', params: { id: this.form.id } })
          console.log('SUCCESS EDITED: ', response.data)
        }).catch((error => {
          console.log('ERROR: ', error),
          console.log('DATA: ', {...this.form})
        }))
      } else {
        HTTP.post('clients/', this.form).then(response => {
          this.$router.push({ name: 'client-detail', params: { id: response.data.id } })
          console.log('SUCCESS CREATE: ', response.data)
        }).catch((error => {
          console.log('ERROR: ', error)
        }))
      }
    }
  },
  mounted() {
    this.form = {...this.client}
    if (this.$route.name !== 'client-create') {
      HTTP
      .get(`clients/${this.$route.params.id}`)
          .then(response => (
              console.log('---- FORM MOUNTED', this.client),
                  this.client = response.data,
                  console.log('---- CLIENT MOUNTED', this.client),
                  this.form = {...this.client},
                  console.log('---- FORM AFTER MOUNTED', this.form),
                  console.log(this.$route.params.id)
    ));
    } else {
      console.log('CREATE NEW CLIENT')
    }

  },
  computed: {
    computedDateFormattedMomentjs () {
      return this.form.birthday ? moment(this.form.birthday).format('DD-MM-YYYY') : ''
    },
  }

}
</script>
